@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.hero {
  &__wrapper{
    div &{
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__additional-wrapper {
    background: $bg-white;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    padding: 16px;
    border-radius: 30px;

    @include for-desktop-up {
      padding: 0 60px;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__main-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    @include for-desktop-up {
      display: block;
      max-width: 410px;
    }
  }

  &__title {
    font-size: 38px;
    font-weight: 700;
    line-height: 44px;
    color: #000;
    width: 100%;

    @include for-desktop-up {
      font-size: 42px;
      font-weight: 700;
      line-height: 50px;
      color: $text-dark;
    }
  }

  &__description {
    margin-top: 16px;

    @include for-desktop-up {
      margin-top: 24px;
      max-width: 328px;
    }

    b {
      color: $text-dark;
    }
  }

  &__button {
    margin-top: 30px;

    @include for-desktop-up {
      margin-top: 40px;
      max-width: 238px;
    }
  }

  &__image {
    @include for-desktop-up {
      flex: 1 0 500px;
      max-width: 600px;
    }

    img{
      width: 100%;
    }
  }
}
