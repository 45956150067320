@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.footer {
  background: $footer-bg;

  &__logo {
    @include for-desktop-down {
      margin: 0 auto;
      display: block;
    }
  }

  &__wrapper {
    padding: 50px 16px 24px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;


    @include for-desktop-up {
      padding-top: 60px;
      gap: 10px 60px;
      grid-template-columns: minmax(206px, 1fr) auto minmax(270px, 1fr);
      grid-template-rows: 1fr 1fr;
    }
  }

  &__nav {
    @include for-desktop-down {
      order: 2;
    }
  }

  &__disclaimer {
    @include for-desktop-up {
      grid-column: 2 / span 1;
      grid-row: 1 / span 2;
    }

    @include for-desktop-down {
      order: 3;
    }
  }

  &__disclaimer-title {
    color: $white;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  &__disclaimer-text {
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
  }

  &__list {
    display: flex;
    justify-content: center;

    @include for-desktop-up {
      justify-content: flex-end;
    }

    li {
      padding: 10px 16px;
    }

    a {
      color: $white;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-transform: uppercase;

      &:hover {
        color: $button-color-hover;
      }
    }
  }

  &__copy {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;

    @include for-desktop-up {
      text-align: right;
      padding-right: 16px;

      grid-column: 3 / span 1;
      grid-row: 2 / span 1;
    }

    @include for-desktop-down {
      order: 4;
    }

    br {
      @include for-desktop-down {
        display: none;
      }
    }
  }
}
