@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.ticker {
  &__wrapper {
    padding: 0 !important;
    max-width: 100vw !important;
  }

  &__items-wrapper {
    display: flex;
    gap: var(--gap);
  }

  &__items {
    display: flex;
    gap: var(--gap);
    animation: scroll-x var(--duration) linear infinite;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 30px;

    @include for-desktop-up {
      gap: 45px;
    }
  }
}

.ticker-logos {
  background: $footer-bg;
  padding: 21px 0;
  overflow: hidden;

  &__title {
    display: inline-block;
    white-space: nowrap;
    color: $white;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    text-transform: uppercase;

    @include for-desktop-up {
      margin-right: 33px;
    }
  }
}

.ticker-choose {
  background: #99fd9b
    linear-gradient(90deg, #89ffbf 0%, #a4fa84 47%, #b3a7ff 100%);
  padding: 21px 0;
  overflow: hidden;

  &__title {
    display: inline-block;
    white-space: nowrap;
    color: #000;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    text-transform: uppercase;

    @include for-desktop-up {
      margin-right: 33px;
    }
  }
}

@keyframes scroll-x {
  from {
    transform: translateX(var(--scroll-start));
  }
  to {
    transform: translateX(var(--scroll-end));
  }
}
