html {
  height: 100%;
}

body {
  font-family: "Cabin", sans-serif;
  font-size: $base-font-size;
  font-weight: 400;
  line-height: 22px;
  background-color: $white;
  color: $text;
  min-height: 100%;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App {
  flex: auto;
}

#root a {
  transition: 0.3s ease;
}

.main-wrapper {
  min-width: 0;
}

.wrapper {
  max-width: calc(1170px + 32px);
  width: 100%;
  padding: 50px 16px;
  margin-left: auto;
  margin-right: auto;

  @include for-desktop-up {
    padding: 100px 16px;
  }
}

.disabled-scroll {
  overflow-y: hidden;
  position: relative;

  @include for-desktop-down {
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }
}

.visually-hidden:not(:focus):not(:active) {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  white-space: nowrap;
}

.main-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  color: $text-dark;
}

img {
  width: auto;
  height: auto;
}

* {
  tab-size: 4;
}
