.contents {
  &__list {
    margin-top: 12px;
  }

  &__item {
    a {
      color: $green !important;
      display: inline-block;
      text-decoration: none;
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;

      &:hover {
        color: $button-color-hover;
      }
    }
  }

  &__link {
    display: block;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;

    @include for-desktop-up {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.policy__contents {
  margin-top: 30px;
  margin-bottom: 30px;

  .headline {
    margin-bottom: 12px;
  }
}
