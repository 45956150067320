@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.our-version {
  &__wrapper{
    div &{
      padding-top: 0;
      padding-bottom: 0;

      @include for-desktop-down {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }

  &__additional-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    padding: 50px 0;

    @include for-desktop-up {
      padding: 50px 60px;
      flex-direction: row;
      justify-content: space-between;
      gap: 60px;
    }

    &.reverse {
      @include for-desktop-up {
        flex-direction: row-reverse;
      }
    }

    &:first-child {
      @include for-desktop-up {
        padding-top: 100px;
      }
    }

    &:last-child {
      @include for-desktop-up {
        padding-bottom: 100px;
      }
    }
  }

  &__main-container {
    display: flex;
    flex-direction: column;

    @include for-desktop-up {
      display: block;
      max-width: 495px;
    }
  }

  &__title {
    text-align: left;
  }

  &__description {
    margin-top: 24px;

    p + p{
      padding-top: 24px;
    }
  }

  &__button {
    margin-top: 30px;

    @include for-desktop-up {
      margin-top: 40px;
      max-width: 238px;
    }
  }

  &__image {
    @include for-desktop-up {
      flex: 1 0 450px;
      max-width: 490px;
    }

    img{
      width: 100%;
    }
  }
}
