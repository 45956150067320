// common typography settings
$base-font-size: 16px;

// header height settings
$header-height: 60px;
$header-height-mobile: 60px;

// colors settings
$green: #04AA6E;
$text: #868D98;
$text-dark: #2B313B;
$bg-white: #F9F9F9;
$bg-dark: #242424;
$bg-dark1: #252525;
$white: #ffffff;
$footer-bg: #2B2B2B;

$button-color: #04AA6D;
$button-color-hover: #058454;
$button-pay-color: #FFC43A;
$button-pay-color-hover: #C5972D;

$path-to-static-fonts: '../fonts/';