@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.faq {
  &__wrapper {
    div & {
      padding-top: 50px;
    }
  }

  &__additional-wrapper {
    padding: 0;

    @include for-desktop-up {
      padding: 0 60px;
    }
  }

  &__title {
    text-align: center;
  }

  &__description {
    max-width: 495px;
    margin: 16px auto 0;
    text-align: center;
  }

  &__items {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-row-gap: 22px;

    @include for-desktop-up {
      margin-top: 60px;
    }
  }

  &__item {
    padding: 30px;
    border-radius: 8px;
    background: $bg-white;

    &.active {
      .faq__item-icon {
        transform: rotate(0deg);
      }
    }

    @include for-desktop-up {
    }
  }

  &__item-top {
    display: flex;
    justify-content: space-between;

    @include for-desktop-up {
      cursor: pointer;
    }
  }

  &__item-bottom {
    padding-top: 26px;
    color: $text-dark;
  }

  &__item-icon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    position: relative;
    right: -2px;
    transform: rotate(180deg);
    transition: transform 0.4s ease-out;
  }

  &__item-title {
    color: $text-dark;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    transition: color 0.4s ease-out;
  }
}
