@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.about {
  background: $bg-dark;

  &__additional-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    gap: 24px;
    color: $white;

    @include for-desktop-up {
      grid-template-columns: minmax(0, 450px) minmax(0, 470px);
      gap: 60px;
    }

    @include for-desktop-up-large {
      padding-right: 60px;
    }
  }

  &__title {
    color: $white !important;

    @include for-desktop-up {
      grid-column: 1 / span 1;
      max-width: 470px;
    }
  }

  &__description {
    color: $white;

    @include for-desktop-up {
      grid-column: 2 / span 1;
      max-width: 495px;
    }
  }

  &__items {
    margin-top: 6px;
    display: flex;
    flex-wrap: wrap;
    gap: 24px 0;


    @include for-desktop-up {
      margin-top: 0;
      justify-content: space-between;
      grid-column: 1 / span 2;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 16px;
    border-radius: 8px;
    background: $footer-bg;
    padding: 30px;


    &:nth-child(1) {
      @include for-desktop-up {
        flex: 0 0 calc(33.69% - 15px);
      }

      span {
        background: #8AFEC0;
      }
    }
    &:nth-child(2) {
      @include for-desktop-up {
        flex: 0 0 calc(33.69% - 15px);
      }

      span {
        background: #9DFC96;
      }
    }
    &:nth-child(3) {
      @include for-desktop-up {
        flex: 0 0 calc(32% - 15px);
      }

      span {
        background: #A8E4A6;
      }
    }
    &:nth-child(4) {
      @include for-desktop-up {
        flex: 0 0 calc(50% - 12px);
      }

      span {
        background: #AFC1DA;
      }
    }
    &:nth-child(5) {
      @include for-desktop-up {
        flex: 0 0 calc(50% - 12px);
      }

      span {
        background: #B4ACF9;
      }
    }
  }

  &__item-number {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #9cfc95;
    color: #000;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
  }

  &__item-title {
    flex: 1;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    color: $white;

    @include for-small-mobile {
      font-size: 19px;
      line-height: 25px;
    }
  }

  &__item-text {
    color: $text;
  }
}
