@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.button {
  font-size: 16px;
  color: #FFF;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;
  max-width: 311px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 56px;
  text-align: center;
  height: 56px;
  padding: 0 16px;
  opacity: 1;
  border-radius: 8px;
  transition:
    background-color 0.225s ease,
    color 0.225s ease,
    opacity 0.225s ease;

  background-color: $button-color;

  @include hover-supported {
    background-color: $button-color-hover;
    color: #FFF;
  }

  &:active {
    background-color: $button-color-hover;
  }

  &:disabled {
    user-select: none;
    pointer-events: none;
    opacity: 0.5;
  }

  @include focus-visible;

  &--small {
    max-width: 169px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }

  &--animate {
    border: 0px solid #000;
    //animation: borderPulse 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
    animation: borderPulse 1000ms infinite ease-out;

    &:focus, &:hover {
      animation: borderPulse 1000ms infinite ease-out, hoverShine 200ms;
    }
  }

  &--pay {
    border-radius: 30px;
    background: $button-pay-color;
    color: $text-dark;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-transform: none;
    box-sizing: border-box;

    span {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &:focus, &:hover {
      background: $button-pay-color-hover;
      color: $text-dark;
    }
  }
}


@keyframes colorShift {
  0%, 100% {
    background: #0045e6;
  }
  33% {
    background: #fb3e3e;
  }
  66%{
    background: #0dcc00;
  }
}

@keyframes borderPulse {
  0% {
    box-shadow: inset 0px 0px 0px 5px rgba(5, 170, 109,.4), 0px 0px 0px 0px rgba(5, 170, 109,1);
  }
  100% {
    box-shadow: inset 0px 0px 0px 3px rgba(255, 255, 255,.2), 0px 0px 0px 10px rgba(5, 170, 109,0);
  }
}

@keyframes hoverShine {
  0%{
    background-image: linear-gradient(135deg, rgba(255,255,255,.4) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
  }
  50%{
    background-image: linear-gradient(135deg, rgba(255,255,255,0) 0%, rgba(255,255,255,.4) 50%, rgba(255,255,255,0) 100%);
  }
  100%{
    background-image: linear-gradient(135deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,.4) 100%);
  }
}