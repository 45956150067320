.policy-section {
  &__wrapper {
    position: relative;
    max-width: 938px;
    margin: 0 auto;
    padding: 40px 16px 60px;

    @include for-desktop-up {
      padding: 76px 0 120px;
    }
  }

  &__update {
    .policy-section & {
      color: $text-dark;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &__title {
    color: $text-dark;
    font-size: 38px;
    font-weight: 700;
    line-height: 44px;
    margin-bottom: 40px;
    text-align: center;

    @include for-desktop-up {
      font-size: 42px;
      font-weight: 700;
      line-height: 50px;
    }
  }

  &__title-compatibility {
    @media (max-width: 425px) {
      font-size: 38px;
      line-height: 58px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 21px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 20px;
      line-height: 26px;
      font-weight: 700;
      margin-bottom: 16px;
    }

    h2 {
      color: $text-dark;
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 16px;
    }

    h3 {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-transform: uppercase;
      color: $text-dark;
    }

    h4 {
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
    }

    p,
    ul,
    ol {
      margin-bottom: 24px;
      list-style: disc;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    ul,
    li {
      margin: 5px 0 16px 20px;
    }

    b {
      color: $text-dark;
      text-transform: uppercase;
      font-weight: 700;
    }

    a {
      transition: 0.3s ease;
      color: $button-color;
      text-decoration: underline;

      &:hover {
        color: $button-color-hover;
        text-decoration: none;
      }
    }

    i {
    }

    .sub-title {
      font-size: 18px;
      font-weight: 700;
      text-align: left;
      margin-bottom: 11px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__uppercase {
    text-transform: uppercase;
  }

  &__italic {
    font-style: italic;
  }

  &__underline {
    text-decoration: underline;
  }

  ol {
    list-style: auto;
    counter-reset: item;
    list-style: none;

    li {
      margin-left: 0;
      counter-increment: item;
      display: flex;
      align-items: baseline;
      gap: 12px;

      &::before {
        content: counter(item) ". ";
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: right;
        flex: 0 0 18px;
      }
    }
  }

  ul {
    margin: 0;
    list-style: none;

    li {
      position: relative;
      margin-left: 26px;

      &::before {
        content: "a.";
        display: block;
        position: absolute;
        left: -18px;
        top: -1px;
      }

      &:nth-child(2)::before {
        content: "b.";
      }

      &:nth-child(3)::before {
        content: "c.";
      }

      &:nth-child(4)::before {
        content: "d.";
      }

      &:nth-child(5)::before {
        content: "e.";
      }

      &:nth-child(6)::before {
        content: "f.";
      }
    }
  }

  &__terms {
    .headline {
      margin-left: 8px;
    }

    ul {
      li {
        &::before {
          content: "a)";
        }

        &:nth-child(2)::before {
          content: "b)";
        }

        &:nth-child(3)::before {
          content: "c)";
        }

        &:nth-child(4)::before {
          content: "d)";
        }

        &:nth-child(5)::before {
          content: "e)";
        }

        &:nth-child(6)::before {
          content: "f)";
        }
      }
    }
  }
}

.underline {
  text-decoration: underline;
}

.spacer {
  height: 16px;
  line-height: 16px;
}

.transform-none {
  text-transform: none !important;
}
