.table {
  margin: 40px 0 32px;

  @include for-desktop-down {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding: 1px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #d5d7db;
    margin-bottom: 8px;

    @include for-desktop-up {
      box-shadow: 0 0 0 1px #d5d7db;
      border-style: hidden;
      border-radius: 8px;
    }

    td,
    th {
      border: 1px solid #d5d7db;
      text-align: left;
      vertical-align: middle;
    }
  }

  th {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: $text-dark;
  }

  td,
  th {
    padding: 10px 16px;
  }

  td {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    vertical-align: top;
  }

  &--principles {
    table {
      min-width: 938px;

      tr td:first-child {
        color: $text-dark;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
      }

      td,
      th {
        &:nth-child(1) {
          width: 4.69%;
        }

        &:nth-child(2) {
          width: 23.82%;
        }

        &:nth-child(3) {
          width: 23.82%;
        }

        &:nth-child(4) {
          width: 23.82%;
        }

        &:nth-child(5) {
          width: 23.82%;
        }
      }
    }
  }

  &--rights {
    table {
      table-layout: fixed;

      @media (max-width: 600px) {
        min-width: 200%;
      }

      td,
      th {
        padding: 16px;
        vertical-align: top;
        font-size: 16px;
        line-height: 22px;
      }

      b {
        text-transform: none;
      }
    }
  }
}
