@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.donate {
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    gap: 24px;

    @include for-desktop-up {
      grid-template-columns: 1fr 1fr;
      gap: 60px;
    }
  }

  &__title {
    @include for-desktop-up {
      grid-column: 1 / span 1;
      max-width: 470px;
    }
  }

  &__description {
    justify-self: flex-end;

    @include for-desktop-up {
      grid-column: 2 / span 1;
      max-width: 470px;
    }

    @include for-desktop-up-large {
      margin-right: 60px;
    }

    p + p {
      margin-top: 16px;
    }
  }

  &__items {
    margin-top: 6px;

    @include for-desktop-up {
      margin-top: 0;
      grid-column: 1 / span 2;
    }

    @media (min-width: 1200px) {
      display: flex;
      gap: 24px;
    }
  }

  &__item {
    flex: 1;
    background: $bg-white;
    padding: 30px 24px;
    border-radius: 8px;
  }

  &__feature {
    display: flex;
    gap: 10px;
    padding: 16px 0;
    border-bottom: 1px solid #dfdfdf;

    &:first-child {
      padding-top: 0;

      p {
        min-height: 80px;
      }
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  &__feature-title {
    flex: 0 0 65px;
    color: $text-dark;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
  }

  &__feature-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    min-height: 60px;

    b {
      color: $text-dark;
    }
  }

  &__price {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text-dark;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    text-transform: uppercase;
    text-align: center;
    margin: 24px 0 16px;
    position: relative;

    sup {
      font-size: 19px;
      position: relative;
      top: -7px;
    }
  }

  &__slider {
    max-width: calc(100vw - 32px);
  }

  &__slider-item {
    max-width: 275px;
    flex: none;
  }

  &__scrollbar {
    margin-bottom: 8px;
    width: 100%;
    position: relative;
    display: block;
    background: $bg-white;
    border-radius: 2px;
    height: 4px;

    @include for-desktop-up {
      margin-top: 50px;
      margin-bottom: 50px;
      margin-left: 0;
    }
  }
}
