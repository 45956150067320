@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.nav {
  position: fixed;
  right: 16px;
  bottom: 0;
  width: 205px;
  height: fit-content;
  z-index: 1;
  text-align: right;
  top: $header-height-mobile;
  background-color: transparent;
  padding: 0px 16px 20px 20px;
  border-radius: 8px 0px 8px 8px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.225s ease;

  @include for-desktop-up {
    position: static;
    padding: 0;
    width: auto;
    opacity: 1;
    pointer-events: auto;
    transition: none;
    overflow: visible;
    box-shadow: none;
    text-align: center;
  }

  &.is-opened {
    opacity: 1;
    transform: translate(0, 0);
    pointer-events: auto;
    background-color: $bg-dark1;
  }

  &.is-hide-desktop {
    @include for-desktop-up {
      display: none;
    }
  }

  &-items {
    @include for-desktop-up {
      display: flex;
    }
  }

  &-item {
    position: relative;
    @include hover-supported {
      .nav-link {
        color: $button-color-hover;
      }
    }

    &.is-active {
      .nav-link {
        color: #63c1db;
      }
    }
  }

  &-link {
    display: block;
    padding: 10px 0;
    color: $white;
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;

    @include for-desktop-up {
      display: flex;
      align-items: center;
      padding: 10px 16px;
      color: $text-dark;
      height: $header-height-mobile;
      transition: color 0.225s ease;
    }

    @include for-tablet {
      padding: 0 20px;
    }

    &.active {

      @include for-desktop-down{
        color: #05AA6D;
      }
    }

    @include focus-visible;
  }

  &-text {
    position: relative;
    white-space: nowrap;
  }

  &__button {
    margin-top: 10px;

    @include for-desktop-up {
      display: none;
    }
  }
}
